// import Cookies from 'quasar/src/plugins/Cookies.js';import Loading from 'quasar/src/plugins/Loading.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import SessionStorage from 'quasar/src/plugins/SessionStorage.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import {
  // qPlugIn
  Cookies,
  Loading,
  LocalStorage,
  SessionStorage,
  Notify,
  Dialog,
} from "quasar";
import "./styles/quasar.scss";
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import lang from "quasar/lang/es.js";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    loading: {
      /* look at QuasarConfOptions from the API card */
    },
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
    // dialog: {
    //   /* look at QuasarConfOptions from the API card */
    // },
  },
  plugins: {
    Cookies,
    LocalStorage,
    SessionStorage,
    Loading,
    Notify,
    Dialog,
  },
  lang: lang,
  iconSet: iconSet,
};
