<template>
  <div
    class="home full-width row wrap justify-center items-center content-center q-pa-none q-ma-none q-px-sm"
    style="min-height: calc(100vh - 50px)"
  >
    <q-card
      class="full-width row wrap justify-center items-center content-start q-pa-none q-ma-none"
      style="min-height: calc(100vh - 60px)"
    >
      Home
    </q-card>
  </div>
</template>

<script>
import {
  // defineAsyncComponent,
  ref,
  onMounted,
} from "vue";
import { router } from "@/router";
import {
  isSignedIn,
  // handleClientLoad,
  handleAuthClick,
  // handleSignoutClick,
} from "@/api/gapi/gapi";

export default {
  name: "Home",
  components: {
    // VinculacionExterna: defineAsyncComponent(() =>
    //   import("@/views/vinculacionexterna/views/VinculacionExterna.vue")
    // ),
    // VinculacionExterna,
  },
  setup() {
    console.log("Home ready");
    const auth2 = ref(null);
    // handleClientLoad();
    onMounted(() => {});
    return {
      isSignedIn,
      auth2,
      handleAuthClick,
      // handleSignoutClick,
    };
  },
  beforeRouteEnter() {
    if (isSignedIn) {
      router.push("/indicadores");
    }
  },
};
</script>
