import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { gapi, updateSigninStatus } from "../api/gapi/gapi";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      permission: {
        roles: ["ORGANIZER", "FILE_ORGANIZER", "EDIT", "COMMENT", "VIEW"],
        config: {
          noAccessRedirect: "/forbidden",
          // debug: true,
        },
      },
    },
  },
  {
    path: "/indicadores",
    name: "Indicadores",
    component: () =>
      import(
        /* webpackChunkName: "indicadores" */ "../views/indicadores/views/Indicadores.vue"
      ),
    meta: {
      permission: {
        roles: ["ORGANIZER", "FILE_ORGANIZER", "EDIT", "COMMENT", "VIEW"],
        config: {
          noAccessRedirect: "/forbidden",
          // debug: true,
        },
      },
    },
  },
  {
    path: "/vinculacionexterna",
    name: "VinculacionExterna",
    component: () =>
      import(
        /* webpackChunkName: "vinculacionexterna" */ "../views/vinculacionexterna/views/VinculacionExterna.vue"
      ),
    meta: {
      permission: {
        roles: ["ORGANIZER", "FILE_ORGANIZER", "EDIT", "COMMENT", "VIEW"],
        config: {
          noAccessRedirect: "/forbidden",
          // debug: true,
        },
      },
    },
  },
  {
    path: "/vinculacioninterna",
    name: "VinculacionInterna",
    component: () =>
      import(
        /* webpackChunkName: "vinculacioninterna" */ "../views/vinculacioninterna/views/VinculacionInterna.vue"
      ),
    meta: {
      permission: {
        roles: ["ORGANIZER", "FILE_ORGANIZER", "EDIT", "COMMENT", "VIEW"],
        config: {
          noAccessRedirect: "/forbidden",
          // debug: true,
        },
      },
    },
  },
  {
    path: "/login",
    name: "LogIn",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LogIn.vue"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/Forbidden.vue"),
    meta: {
      permission: {
        roles: ["NONE"],
        config: {
          noAccessRedirect: "/login",
          // debug: true,
        },
      },
    },
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/PageNotFound.vue"),
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // If there are no permissions to check then proceed
  if (!to.meta.permission) return next();

  const { roles = [], config = {} } = to.meta.permission;
  // If there are no roles then proceed
  if (!roles.length) return next();
  // Check if user should have access to the next page
  // const hasAccess = checkPermission(roles, config);
  // const hasAccess = false;
  const hasAccess = await updateSigninStatus(
    await gapi.value.auth2.getAuthInstance().isSignedIn.get()
  );
  // console.log(hasAccess[1]);
  // console.log(roles.indexOf(hasAccess[1]));
  // Access granted
  if (!hasAccess[0]) {
    return next("/login");
  } else if (roles.indexOf(hasAccess[1]) > -1) {
    return next();
  }
  // No access!
  // next(to.meta.permission?.noAccessRedirect || "/forbidden");
  return next(config?.noAccessRedirect || "/forbidden");
});

export default router;
