import { createApp } from "vue";
// import {
//   CLIENT_ID,
//   API_KEY,
//   DISCOVERY_DOCS,
//   SCOPES,
// } from "@/api/gapi/gapiConstants";
// import { loadGapiScript, client, updateSigninStatus } from "./api/gapi/gapi";
import { handleClientLoad } from "./api/gapi/gapi";
// handleClientLoad();
// console.log("handleClientLoad(): " + JSON.stringify(isSignedIn.value));
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
// import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";

// loadGapiScript
// async function loadApp() {

// }
handleClientLoad()
  .then(() => {
    createApp(App)
      .use(Quasar, quasarUserOptions)
      .use(store)
      .use(router)
      .mount("#app");
  })
  .catch((err) => {
    console.log(err);
  });

// loadGapiScript().then((gapi) => {
//   return new Promise((resolve, reject) => {
//     gapi.load("client:auth2", () => {
//       gapi.client
//         .init({
//           clientId: CLIENT_ID,
//           apiKey: API_KEY,
//           discoveryDocs: DISCOVERY_DOCS,
//           scope: SCOPES,
//         })
//         .then(async () => {
//           // console.log("loadGapiScript-init-then");
//           // console.log("before: " + JSON.stringify(client.value));
//           client.value = {
//             gapi,
//             authInstance: gapi.auth2.getAuthInstance(),
//           };
//           // console.log(
//           //   "after: " +
//           //     JSON.stringify(
//           //       client.value.authInstance.currentUser.get().getBasicProfile()
//           //     )
//           // );
//           await updateSigninStatus(client.value.authInstance.isSignedIn.get());
//           console.log("loadGapiScript");
//           createApp(App)
//             .use(Quasar, quasarUserOptions)
//             .use(store)
//             .use(router)
//             .mount("#app");
//           resolve(client);
//         }, reject);
//     });
//   });
// });
