<template>
  <main-layout>
    <!-- <router-view></router-view> -->
  </main-layout>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    MainLayout: defineAsyncComponent(
      () => import("./views/mainlayout/views/MainLayout.vue")
    ),
  },
  setup() {
    // console.log("process.env: " + JSON.stringify(process.env));
    if (process.env.VUE_APP_NOT_SECRET_CODE) {
      console.log("is true VUE_APP_NOT_SECRET_CODE");
    }
    // console.log(
    //   "VUE_APP_NOT_SECRET_CODE: " + process.env.VUE_APP_NOT_SECRET_CODE
    // );
    // console.log(
    //   "VUE_APP_NOT_SECRET_CODE_2: " + process.env.VUE_APP_NOT_SECRET_CODE_2
    // );
    // console.log("FOO: " + process.env.FOO);
    console.log("App ready");
    return {};
  },
});
</script>
<style lang="scss">
.q-card {
  background: rgba(255, 255, 255, 0.3) !important;
}

.q-menu {
  background: rgba(255, 255, 255, 0.7) !important;
}

.q-tab-panels,
.q-tab-panel,
.q-panel {
  background: rgba(255, 255, 255, 0) !important;
}

.q-virtual-scroll__content {
  background: rgba(255, 255, 255, 0.5) !important;
}

.hit-the-floor {
  color: rgba(255, 255, 255, 0.7); // rgba(0, 27, 88, 0.7); // #fff;
  font-size: 12em;
  font-weight: bold;
  font-family: Helvetica;
  text-shadow: 0 1px 0 rgba(204, 204, 204, 0.7),
    0 2px 0 rgba(201, 201, 201, 0.7), 0 3px 0 rgba(187, 187, 187, 0.7),
    0 4px 0 rgba(185, 185, 185, 0.7), 0 5px 0 rgba(170, 170, 170, 0.7),
    0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

.hit-the-floor {
  text-align: center;
}
</style>
