import { SCRIPT_ID } from "@/api/gapi/gapiConstants";
import { gapi } from "@/api/gapi/gapi";

export const callScriptFunction = async (gapiFunction, gapiParams) => {
  // Call the Apps Script API run method
  //   'scriptId' is the URL parameter that states what script to run
  //   'resource' describes the run request body (with the function name
  //              to execute)
  const scriptId = SCRIPT_ID;
  // window.gapi.client.script.scripts
  const clientResponse = await gapi.value.client.script.scripts.run({
    scriptId: scriptId,
    resource: {
      function: gapiFunction,
      parameters: Array.isArray(gapiParams) ? gapiParams : [gapiParams], // gapiParams must be an Array
    },
  });
  // console.log(JSON.stringify(clientResponse, null, "\t"));
  if (clientResponse.result.error && clientResponse.result.error.status) {
    // The API encountered a problem before the script
    // started executing.
    console.log("Error calling API:");
    console.log(JSON.stringify(clientResponse.result, null, "\t"));
    return "Error calling API";
  } else if (clientResponse.result.error) {
    // The API executed, but the script returned an error.
    // Extract the first (and only) set of error details.
    // The values of this object are the script's 'errorMessage' and
    // 'errorType', and an array of stack trace elements.
    var error = clientResponse.result.error.details[0];
    console.log("Script error message: " + error.errorMessage);
    if (error.scriptStackTraceElements) {
      // There may not be a stacktrace if the script didn't start
      // executing.
      console.log("Script error stacktrace:");
      for (var i = 0; i < error.scriptStackTraceElements.length; i++) {
        var trace = error.scriptStackTraceElements[i];
        console.log(JSON.stringify(trace, null, "\t"));
        console.log("\t" + trace.function + ":" + trace.lineNumber);
      }
    }
    return "Script error message";
  } else {
    return clientResponse.result.response.result;
  }
};

/**
 * Append a pre element to the body containing the given message
 * as its text node. Used to display the results of the API call.
 *
 * @param {string} message Text to be placed in pre element.
 */
export const appendPre = (message = null) => {
  console.log(message);
  // var pre = document.getElementById("content");
  // var textContent = document.createTextNode(message + "\n");
  // pre.appendChild(textContent);
};

export const consoleLog = (params) => {
  console.log(params);
};
