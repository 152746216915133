import {
  ref,
  // readonly,
} from "vue";
import { router } from "@/router";
// import { gapi } from "@/api/gapi/google_gapi";
import {
  CLIENT_ID,
  API_KEY,
  DISCOVERY_DOCS,
  SCOPES,
} from "@/api/gapi/gapiConstants";

import {
  callScriptFunction,
  // consoleLog,
} from "@/api/gapi/gapiCallScriptFunctions";

import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';;

const qLoadingoptions = {
  delay: 400, // ms
  spinner: QSpinnerGears,
  spinnerColor: "rgba(255, 255, 255, 0.99)",
  messageColor: "rgba(255, 255, 255, 0.99)",
  backgroundColor: "primary",
  message: "<span class='text-h6'><b>" + "Accediendo" + "</b></span>",
  html: true,
  sanitize: true,
};

export const gapi = ref(null);

export const client = ref({
  gapi: null,
  authInstance: null,
});

export const isSignedIn = ref(false);
export const basicProfile = ref({});

/**
 *  Called when the signed in status changes, to update the UI
 *  appropriately. After a sign-in, the API is called.
 */
export const updateSigninStatus = async (isSignedInResponse) => {
  // console.log("updateSigninStatus");
  isSignedIn.value = isSignedInResponse;
  if (isSignedInResponse) {
    // console.log(
    //   "GoogleAuth.currentUser.get(): " +
    //     JSON.stringify(window.gapi.auth2.getAuthInstance().currentUser.get())
    // );
    // console.log(gapi.value);
    const getBasicProfile = gapi.value.auth2
      .getAuthInstance()
      .currentUser.get()
      .getBasicProfile();
    basicProfile.value["id"] = getBasicProfile.getId();
    basicProfile.value["name"] = getBasicProfile.getName();
    basicProfile.value["givenName"] = getBasicProfile.getGivenName();
    basicProfile.value["familyName"] = getBasicProfile.getFamilyName();
    basicProfile.value["imageUrl"] = getBasicProfile.getImageUrl();
    basicProfile.value["email"] = getBasicProfile.getEmail();
    // checkUserAcces(emailUser = "NONE", folderId = "113Xnq0UCltvLuqN6SW76btt_7jzgmbFb")
    basicProfile.value["role"] = await callScriptFunction("checkUserRole", [
      basicProfile.value["email"],
    ]);
    return [isSignedInResponse, basicProfile.value["role"]];
  } else {
    basicProfile.value = {};
    return [isSignedInResponse, "NoLogin"];
  }
  // console.log(
  //   "GoogleAuth.currentUser.get().getBasicProfile(): " +
  //     JSON.stringify(basicProfile.value)
  // );
};

/**
 *  Sign in the user upon button click.
 */
export const handleAuthClick = async () => {
  Loading.show(qLoadingoptions);
  // console.log("handleAuthClick");
  // await client?.value.gapi.auth2.getAuthInstance().signIn();
  // updateSigninStatus(
  //   client?.value.gapi.auth2.getAuthInstance().isSignedIn.get()
  // );
  try {
    await gapi.value.auth2.getAuthInstance().signIn();
    // console.log("result: " + JSON.stringify(result));
    const signinStatus = await updateSigninStatus(
      gapi.value.auth2.getAuthInstance().isSignedIn.get()
    );
    if (signinStatus[0]) {
      Loading.show({
        delay: 400, // ms
        spinner: QSpinnerGears,
        spinnerColor: "rgba(255, 255, 255, 0.99)",
        messageColor: "rgba(255, 255, 255, 0.99)",
        backgroundColor: "positive",
        message:
          "<span class='text-h6'><b>" + "Acceso concedido" + "</b></span>",
        html: true,
        sanitize: true,
      });
      // window.open("/", "_self");
      router.push("/");
    }
  } catch (error) {
    Loading.show({
      delay: 0, // ms
      spinner: QSpinnerGears,
      spinnerColor: "rgba(255, 255, 255, 0.99)",
      messageColor: "rgba(255, 255, 255, 0.99)",
      backgroundColor: "negative",
      message: "<span class='text-h6'><b>" + "Acceso denegado" + "</b></span>",
      html: true,
      sanitize: true,
    });
    console.log(error);
  } finally {
    setTimeout(function () {
      Loading.hide();
    }, 2500);
  }
};

/**
 *  Sign out the user upon button click.
 */
export const handleSignoutClick = async () => {
  // console.log("handleSignoutClick");
  // await client?.value.gapi.auth2.getAuthInstance().signOut();
  // updateSigninStatus(
  //   client?.value.gapi.auth2.getAuthInstance().isSignedIn.get()
  // );
  await gapi.value.auth2.getAuthInstance().signOut();
  const signinStatus = await updateSigninStatus(
    gapi.value.auth2.getAuthInstance().isSignedIn.get()
  );
  if (!signinStatus[0]) {
    // window.open("/login", "_self");
    router.push("/login");
  }
};

/**
 *  Revokes all of the scopes that the user granted.
 */
export const handleDisconnectClick = async () => {
  // console.log("handleDisconnectClick");
  await gapi.value.auth2.getAuthInstance().disconnect();
  updateSigninStatus(gapi.value.auth2.getAuthInstance().isSignedIn.get());
  router.push("/login");
};

/**
 * Append a pre element to the body containing the given message
 * as its text node. Used to display the results of the API call.
 *
 * @param {string} message Text to be placed in pre element.
 */
export const appendPre = (message) => {
  var pre = document.getElementById("content");
  var textContent = document.createTextNode(message + "\n");
  pre.appendChild(textContent);
};

// GapiClientProvider
export const loadGapiScript = async () => {
  gapi.value = await new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onreadystatechange = script.onload = function () {
      const interval = setInterval(function () {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          clearInterval(interval);
          if (window.gapi) {
            resolve(window.gapi);
          } else {
            reject("Can't load gapi");
          }
        }
      }, 100);
    };
    script.onerror = function (message, url, line, column, error) {
      reject({ message, url, line, column, error });
    };
    document.getElementsByTagName("head")[0].appendChild(script);
  });
  // console.log(gapi.value);
};

export const handleClientLoad = async () => {
  await loadGapiScript();
  // console.log("gapi handleClientLoad");
  // await client?.gapi.load("client:auth2", initClient);
  client.value = await new Promise((resolve, reject) => {
    gapi.value.load("client:auth2", async () => {
      gapi.value.client
        .init({
          clientId: CLIENT_ID,
          apiKey: API_KEY,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(async () => {
          let thisClient = {
            gapi,
            authInstance: gapi.value.auth2.getAuthInstance(),
          };
          await updateSigninStatus(
            gapi.value.auth2.getAuthInstance().isSignedIn.get()
          );
          resolve(thisClient);
        }, reject);
    });
  });
  // console.log("client.value: " + client.value);
  console.log("handleClientLoad");
};
