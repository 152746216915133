export const CLIENT_ID =
  // "33308384271-hgtm37v4auvcca42hlph7ohd7duhi8em.apps.googleusercontent.com"
  "33308384271-55rqgf90vpffduukbmotd67q7keqk8i2.apps.googleusercontent.com";
export const API_KEY = "AIzaSyBz62hVliZ9SP5mYCgJ-c09ZyPhuo5RywM";
export const DISCOVERY_DOCS = [
  "https://script.googleapis.com/$discovery/rest?version=v1",
];
export const SCOPES = [
  // "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/script.projects",
  "https://www.googleapis.com/auth/script.send_mail",
  "https://www.googleapis.com/auth/script.external_request",
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/spreadsheets",
  "https://www.googleapis.com/auth/forms",
  "https://www.googleapis.com/auth/gmail.send",
].join(" ");
export const SCRIPT_ID =
  // "AKfycbx6WduEICEC0jhtmYevXj61WNCOnog5acYh7HoL80a5qsVMeNBlX2X9MAlcsTCQ59eeCQ"
  "AKfycbwexhutWibd2OJiSL79ClOoWwtiGHcSGeU_9B88IohKF2iMSad7U087lYFHBT7cuiz7";
export const GOOGLE_DRIVE_IDS = {
  GOOGLE_FORMS_NUEVAS: "1MQzUTRAN3JHsAXKLoF4JSkZg0wvX1MP1wuOMurW2igI", // Solicitudes de Servicios Tecnológicos
  GOOGLE_FORMS_SEGUIMIENTO: "1pjHjfDQTWXjNjbBfvwYCHB3gmPGQzVZcXuINX54VuKQ", // Seguimiento de Solicitudes de Servicios
  GOOGLE_FORMS_CANALIZADAS: "",
  GOOGLE_SHEET_NUEVAS: "1dYpqALEZWsSrxIvaVwE15uJO21pApIwkIi8kxcwvWnc", // Solicitudes de Servicios Tecnológicos (Respuestas)
  GOOGLE_SHEET_SEGUIMIENTO: "1FRynrYynNTGi2y8zvYu21E7j-KhielV-BmprhGjdp8c", // Seguimiento de Solicitudes de Servicios (Respuestas)
  GOOGLE_SHEET_CANALIZADAS: "1WfDOHrVcQxJ_3rZ3cOXMSnfoTjcKr43Eu92AAAKYWKs", // Solicitudes Canalizadas
};
