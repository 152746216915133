import { render } from "./App.vue?vue&type=template&id=62e89e74"
import script from "./App.vue?vue&type=script&lang=ts"
export * from "./App.vue?vue&type=script&lang=ts"

import "./App.vue?vue&type=style&index=0&id=62e89e74&lang=scss"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QMenu,QTabPanels,QTabPanel,QVirtualScroll});
